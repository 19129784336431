import { useState } from 'react'
import styled from 'styled-components'
import device from '../../styles/device'

import { 
  NavListItems,
  NavLink 
} from './navbar.elements'

const Dropdown = styled.div`
  display: block;

  * {
    display: block;
    border: 1px solid #fff;
    border-radius: 10px;
  }

  @media ${device.xl} {
    * {
      border: none;
      border-radius: 0;
    }
  }
`

const NavDropdown = ({ text, href, children }) => {
  const [visible, setVisible] = useState(false)

  return (
    <NavListItems 
      onMouseOver={(e) => setVisible(true)}
      onMouseLeave={(e) => setVisible(false)}
    >
      <NavLink href={`${href}`}>{text}</NavLink>
      {visible && (
        <Dropdown>
          {children}
        </Dropdown>
      )}
    </NavListItems>
  )
}
 
export default NavDropdown