import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { persistor } from '../../redux'

import { logout } from '../../redux/slices/authSlice'
import { resetCart } from '../../redux/slices/cartSlice'
import { resetScanViewed } from '../../redux/slices/userStatus'

import { 
  NavLink,
} from './navbar.elements'

const LogoutButton = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  const handleClick = (e) => {
    e.preventDefault()
    persistor.flush()
    dispatch(logout())
    dispatch(resetCart())
    dispatch(resetScanViewed())
    router.push('/')
  }

  return (
    <NavLink onClick={handleClick} href='#'>Cerrar sesión</NavLink>
  )
}

export default LogoutButton
