import styled from 'styled-components'
import Link from 'next/link'
import { Button } from '../../styles/global.elements'
import device from '../../styles/device'
import 'typeface-mulish'
import 'typeface-muli'

const Nav = styled.nav`
  background-color: ${props => props.theme.primary.main};
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  height: 4rem;
  z-index: 1;
`

const NavBrand = styled.div`
  flex: 5;
  padding: 0.3rem;
  font-family: 'Muli', sans-serif;
  cursor: pointer;

  @media ${device.sm} {
    flex: 3;
  }

  @media ${device.md} {
    flex: 2;
  }

  @media ${device.lg} {
    flex: 1.5;
  }

  @media ${device.xl} {
    flex: 1;
  }
`

const NavButtons = styled.div`
  display: ${props => props.active ? 'block' : 'none'};
  width: 100%;

  @media ${device.xl} {
    display: inline-block;
    height: 100%;
    flex: 10;
    margin-right: .5rem;
  }
`

const NavList = styled.ul`
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  
  @media ${device.xl} {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }
`

const NavListItems = styled.li`
  margin: 0 0.1rem;

  @media ${device.xl} {
    height: 100%;
  }
`

const NavATag = styled.a`
  display: block;
  font-family: 'Mulish';
  font-size: 0.6rem;
  font-weight: bold;
  letter-spacing: .1rem;
  text-align: center;
  text-transform: uppercase;
  background-color: ${props => props.theme.primary.main};
  color: white;
  text-decoration: none;
  padding: 0.8rem 0.4rem;

  &:hover {
    background-color: ${props => props.theme.primary.light};
    color: black;
  }

  &:active {
    background-color: ${props => props.theme.primary.dark};
    color: white;
  }

  @media ${device.xl} {
    display: inline-block;
    height: 100%;
    width: 7.2rem;
  }
`

const NavMenuButton = styled(Button)`
  flex: 1;
  align-self: center;
  width: 50%;
  height: 80%;
  margin-right: .5rem;

  @media ${device.xl} {
    display: none;
  }
`

const NavLink = ({ href, children, ...rest }) => (
  <Link href={href} passHref>
    <NavATag {...rest}>{children}</NavATag>
  </Link>
)

export { 
  Nav, 
  NavBrand,
  NavButtons,
  NavList,
  NavListItems,
  NavLink,
  NavMenuButton
}