import Navbar from '../navbar'
import Footer from '../footer'

import {
  Main,
  Container
} from './layout.elements'

const Layout = ({ children }) => (
  <div>
    <Navbar />
    <Main>
      <Container>
        {children}
      </Container>
    </Main>
    <Footer />
  </div>
)
 
export default Layout
