import styled, { css } from 'styled-components'
import { space, layout } from 'styled-system'

const ErrorMessage = styled.span`
  color: red;
  font-size: .7rem;
  line-height: .5rem;
  letter-spacing: .05rem;
`

const inputStyles = css`
  display: block;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.primary.main};
  font-size: 1rem;
  color: #4F4F4F;
  width: 100%;
  padding: 0.5rem;
`

const InputWrapper = styled.div`
  width: 100%;

  ${space}
  ${layout}
`;

const Input = styled.input`
  ${inputStyles}
`;

const TextArea = styled.textarea`
  ${inputStyles}
  resize: vertical;
`;

const Select = styled.select`
  ${inputStyles}
`

const Label = styled.label`
  display: block;
  font-family: 'Mulish';
  font-size: 1rem;
  padding: .5rem 0;
  
  ${space}
  ${layout}
`

const InputField = (props) => {
  if (props.type === "select") return (
    <InputWrapper p={props.p} m={props.m}>      
      {props.title && <Label htmlFor={props.name}>{props.title}</Label>} 
      <Select {...props}>
        {props.children}
      </Select>
      {props.error && (
        <ErrorMessage>
          {props.error}
        </ErrorMessage>
      )}
    </InputWrapper>
  )

  if (props.type === "textarea") return (
    <InputWrapper p={props.p} m={props.m}>      
      {props.title && <Label htmlFor={props.name}>{props.title}</Label>} 
      <TextArea {...props} />
      {props.error && (
        <ErrorMessage>
          {props.error}
        </ErrorMessage>
      )}
    </InputWrapper>
  )

  return (
    <InputWrapper p={props.p} m={props.m}>
      {props.title && <Label htmlFor={props.name}>{props.title}</Label>}    
      <Input type={props.type} {...props} />
      {props.error && (
        <ErrorMessage>
          {props.error}
        </ErrorMessage>
      )}
    </InputWrapper>
  )
}


const RadioWrapper = styled.div`
  display: inline-block;
  padding: .4rem 0;

  ${space}
  ${layout}
`;

const Mark = styled.span`
  background-color: white;
  display: inline-block;
  position: relative;
  border: 1px solid ${props => props.theme.primary.main};
  min-width: 1.8rem;
  height: 1.8rem;
  left: 0;
  border-radius: 50%;
  margin-right: .5rem;
  vertical-align: middle;
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: ${props => props.theme.primary.main};
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }
`;

const RadioInput = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;
  &:checked + ${Mark} {
    &::after {
      width: 1.3rem;
      height: 1.3rem;
      opacity: 1;
      left: 12%;
      top: 12%;
    }
  }
`;

const RadioLabel = styled.label`
  display: flex;
  cursor: pointer;
  padding: 5px 10px 5px 0;
  position: relative;
  font-size: .9rem;
  ${props =>
    props.disabled &&
    `
        cursor: not-allowed;
        opacity: 0.4;
    `}
`;

const Radio = ({ name, checked, children, onChange, onClick, ...rest }) => (
  <RadioWrapper {...rest}>
    <RadioLabel>
      <RadioInput
        checked={checked} 
        name={name} 
        onClick={onClick} 
        onChange={onChange ? onChange : () => {}}
        type="radio" />
      <Mark />
      {children}
    </RadioLabel>
  </RadioWrapper>
)

export {
  Input,
  Label,
  Select,
  InputField,
  Radio
}