import { createSlice } from "@reduxjs/toolkit"

const cartSlice = createSlice({
  name: 'cart',
  initialState: [],
  reducers: {
    addToCart: (state, action) => void(state.push(action.payload)),
    removeFromCart: (state, action) => 
      state.filter(request => request.tempId != action.payload.tempId),
    resetCart: _ => []
  }
})

export default cartSlice.reducer
export const { 
  addToCart, 
  removeFromCart,
  resetCart
 } = cartSlice.actions