import styled from 'styled-components'
import device from '../../styles/device'
import 'typeface-mulish'
import 'typeface-muli'

const Main = styled.div`
  margin-top: 3.8rem;
  min-height: 100vh;
  background-image: url('/images/mainBG-min.jpg');
  background-size: cover;
`

const Container = styled.div`
  max-width: 90vw;
  margin: auto;
  padding: 1rem 0;

  @media ${device.sm} {
    max-width: 90vw;
    padding: 1rem;
  }

  @media ${device.md} {
    max-width: 90vw;
    padding: 2rem;
  }

  @media ${device.xl} {
    max-width: 85vw;
    padding: 4rem;
  }
`

export {
  Main,
  Container
}